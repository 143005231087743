<template>
  <div class="bg-white border text-center position-relative rounded my-3">
    <p class="p-1 fw-bold mb-0">
      {{ codeText }}
      <span v-if="copied" class="text-success">{{ $t('js.election_client.ballot_verification.copied') }}</span>
    </p>

    <h1 class="text-dark mb-3" role="button" @click="copyToClipboard">
      <code class="text-primary" style="letter-spacing: 5px">{{ displayCode }}</code>
    </h1>

    <div class="d-flex justify-content-center align-items-center flex-column my-3">
      <div
        class="d-flex justify-content-center align-items-center flex-column border border-3 border-dark rounded p-3"
        style="max-width: 250px"
      >
        <h3 class="mb-3 text-center text-capitalize">{{ $t('js.election_client.actions.scan') }}</h3>
        <QrcodeVue :value="qrLink" :size="200" />
      </div>
      <div class="d-flex justify-content-center align-items-center flex-column" style="max-width: 250px">
        <p class="small mt-3" v-html="$t(qrHelpTextKey, { href: qrLink })"></p>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";

export default {
  name: 'ScanQr',
  components: {
    QrcodeVue,
  },
  props: {
    displayCode: {
      type: String,
      default: '',
    },
    codeText: {
      type: String,
    },
    qrLink: String,
    qrHelpTextKey: {
      type: String,
      default: 'js.election_client.scan_qr_html',
    },
  },
  data() {
    return {
      copied: false,
    };
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.displayCode);
      this.copied = true;
    },
  },
};
</script>

